import moment from 'moment';

import { Steps } from 'constants/palm-reading-daily';
import { RescanTimers } from 'store/palm-reading-daily/types';

export const getRescanTimerByStep = (timers: RescanTimers, step: Steps) => {
  const now = +moment();
  const nextScan = +moment(timers[step]) || now;
  const diff = nextScan - now;
  const duration = moment.duration(diff, 'milliseconds');
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours() % 24) || (days ? 0 : 1);
  const minutes = Math.floor(duration.asMinutes() % 60);
  const isAvailable = diff <= 0;

  return { days, hours, minutes, isAvailable };
};
