import { Platform } from 'react-native';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { createPalmReport, getPalmReports as requestPalmReports } from 'api/palm-reading';
import { STEPS } from 'constants/palm-reading-daily';
import { AppDispatch, AppGetState } from 'store';
import { getDocumentDirectory } from 'utils/cache-manager';
import { downloadImage } from 'utils/process-image';

import { HANDS, PalmMetadata, TYPES } from './types';

const setReports = createAction(TYPES.SET_REPORTS);
const setReport = createAction(TYPES.SET_REPORT);
const setReportPreview = createAction(TYPES.SET_REPORT_PREVIEW);

// TODO TMP. Delete after release 4.33
export const migrate = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const {
        palmReading: { reports },
        palmReadingDaily,
      } = getState();

      const leftHandImage = palmReadingDaily.left?.image;
      const rightHandImage = palmReadingDaily.right?.image;

      if (!reports?.[HANDS.LEFT]?.created_at && leftHandImage?.uri) {
        const uri = Platform.OS === 'web' ? leftHandImage.uri : getDocumentDirectory() + leftHandImage?.uri?.split('/')?.pop();
        await dispatch(createReport(uri, {}, HANDS.LEFT));
        Analytics.track('PRD_Migration_Success', { hand: HANDS.LEFT });
      }

      if (!reports?.[HANDS.RIGHT]?.created_at && rightHandImage?.uri) {
        const uri = Platform.OS === 'web' ? rightHandImage.uri : getDocumentDirectory() + rightHandImage?.uri?.split('/')?.pop();
        await dispatch(createReport(uri, {}, HANDS.RIGHT));
        Analytics.track('PRD_Migration_Success', { hand: HANDS.RIGHT });
      }
    } catch (e) {
      Analytics.track('PRD_Migration_Error', { error: String(e) });
      console.log('> Migrate to new palm report error', e);
    }
  };
};

export const createReport = (uri: string, metadata: PalmMetadata, hand: HANDS) => {
  return async (dispatch: AppDispatch) => {
    try {
      const report = await createPalmReport(uri, metadata, hand);
      const preview = { uri, date: report.created_at };
      dispatch(setReport({ [hand]: report }));
      dispatch(setReportPreview({ [hand]: preview }));
      Analytics.track('PRD_API_Report_Load_Success');
    } catch (e) {
      console.log('> Create report error', e);
      Analytics.track('PRD_API_Report_Load_Error', { error: (e as Error).message });
    }
  };
};

export const getPalmReports = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { previews } = getState().palmReading;
    const { remoteConfigParams } = getState().remoteConfig;

    if (!remoteConfigParams?.palmReadingLandingEnabled) {
      return false;
    }

    try {
      const reports = await requestPalmReports();
      dispatch(setReports(reports));

      if (reports?.left?.created_at && reports?.left?.created_at !== previews?.left?.date) {
        try {
          const image = await downloadImage(reports.left.path, STEPS.LEFT_HAND);
          const preview = { uri: image.uri, date: reports.left.created_at };
          dispatch(setReportPreview({ left: preview }));
          Analytics.track('PRD_Report_Photo_Downloaded', { hand: HANDS.LEFT });
        } catch (e) {
          console.log('> Download Left palm report photo error', e);
        }
      }

      if (reports?.right?.created_at && reports?.right?.created_at !== previews?.right?.date) {
        try {
          const image = await downloadImage(reports.right.path, STEPS.RIGHT_HAND);
          const preview = { uri: image.uri, date: reports.right.created_at };
          dispatch(setReportPreview({ right: preview }));
          Analytics.track('PRD_Report_Photo_Downloaded', { hand: HANDS.RIGHT });
        } catch (e) {
          console.log('> Download Right palm report photo error', e);
        }
      }

      // TODO TMP. Delete after release 4.33
      dispatch(migrate());
    } catch (e) {
      console.log('> Get reports error', e);
    }
  };
};
