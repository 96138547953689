import { STEPS } from 'constants/palm-reading-daily';

import { PROFILE_TYPES } from '../profile/types';

import { TYPES, HANDS, State, ActionTypes } from './types';

const initialState: State = {
  reports: {
    [HANDS.LEFT]: undefined,
    [HANDS.RIGHT]: undefined,
  },
  // Local photos
  previews: {
    [HANDS.LEFT]: undefined,
    [HANDS.RIGHT]: undefined,
  },
  rescanTimers: {
    [STEPS.LEFT_HAND]: null,
    [STEPS.RIGHT_HAND]: null,
    [STEPS.FINGERS]: null,
  },
  rescanCounters: {
    [STEPS.LEFT_HAND]: 0,
    [STEPS.RIGHT_HAND]: 0,
    [STEPS.FINGERS]: 0,
  },
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_REPORTS:
      return {
        ...state,
        reports: {
          ...action.payload,
        },
      };

    case TYPES.SET_RESCAN_COUNTER:
      return {
        ...state,
        rescanCounters: {
          ...state.rescanCounters,
          [action.payload.stepId]: action.payload.counter,
        },
      };

    case TYPES.SET_RESCAN_TIMER:
      return {
        ...state,
        rescanTimers: {
          ...state.rescanTimers,
          ...action.payload,
        },
      };

    case TYPES.SET_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...action.payload,
        },
      };

    case TYPES.SET_REPORT_PREVIEW:
      return {
        ...state,
        previews: {
          ...state.previews,
          ...action.payload,
        },
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
