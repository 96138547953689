import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, ViewStyle, TextStyle, View } from 'react-native';

import type { ValidMixinsPath } from 'modules/styles/default-styles';
import { dsColor, dsMixin } from 'modules/styles/utils';
import Text from 'components/text';

interface Props {
  level: ValidMixinsPath;
  text?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const Title: FC<Props> = ({ text, level, style, textStyle }) => {
  return (
    <View style={[localStyles.container, style]}>
      <Text style={[localStyles.text, dsMixin(level), textStyle]}>{text}</Text>
    </View>
  );
};

export default memo(Title);

const localStyles = StyleSheet.create({
  container: {
    width: '100%',
  },
  text: {
    color: dsColor('content/primary/solid'),
  },
});
