import React, { FC } from 'react';
import { Image, ImageSourcePropType, ImageStyle, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { paddingHorizontal, sw } from '@wowmaking/ui/src/utils';

import ASTROLOGERS from 'assets/images/astrologers-promo/astrologers-promo-small.png';
import HighLightText from 'components/hight-light-text';
import { dsColor, dsMixin, dsSize, dsSpacing } from 'modules/styles/utils';
import Title from 'modules/styles/components/title';

import ARROW from './image/arrow.png';

interface Props {
  onPress: () => void;
  title: string;
  subTitle?: string;
  style?: StyleProp<ViewStyle>;
  image?: ImageSourcePropType;
  imageStyle?: StyleProp<ImageStyle>;
}

const BannerSmall: FC<Props> = ({ onPress, title, subTitle, image = ASTROLOGERS, imageStyle, style }) => {
  const handlePress = () => {
    onPress();
  };

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={handlePress}>
      <Image source={image} style={[styles.image, imageStyle]} resizeMode="contain" />
      <View style={styles.texts}>
        <Title level="h7/bold" textStyle={styles.title} text={title} />
        {!!subTitle && (
          <HighLightText style={styles.subTitle} highlightedTextStyle={styles.subTitleHightLight}>
            {subTitle}
          </HighLightText>
        )}
      </View>
      <Image source={ARROW} style={styles.arrow} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: dsSize('border_radius/radius_l'),
    backgroundColor: dsColor('surface/hazy'),
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: dsSpacing('spacings/horizontal/h_large'),
    paddingVertical: dsSpacing('spacings/vertical/v_small'),
    overflow: 'hidden',
  },
  texts: {
    flex: 1,
    marginLeft: paddingHorizontal(10),
  },
  title: {
    flexWrap: 'wrap',
  },
  subTitle: {
    ...dsMixin('text_s/regular/medium'),
    color: dsColor('content/primary/neutral'),
    marginTop: dsSpacing('spacings/vertical/v_mini'),
  },
  subTitleHightLight: {
    fontWeight: '600',
  },
  image: {
    width: sw(56),
    height: sw(56),
  },
  arrow: {
    tintColor: dsColor('content/primary/solid'),
  },
});

export default BannerSmall;
