import { STEPS } from 'constants/palm-reading-daily';
import { PROFILE_TYPES } from 'store/profile/types';
import { LinesTransformed, Point } from 'interfaces/palm-reading';
import { PersonalitySlugs } from 'screens/palm-reading/constants/personality';

export enum TYPES {
  SET_REPORT = 'PALM_READING/SET_REPORT',
  SET_REPORTS = 'PALM_READING/SET_REPORTS',
  SET_REPORT_PREVIEW = 'PALM_READING/SET_REPORT_PREVIEW',
  SET_RESCAN_TIMER = 'PALM_READING_DAILY/SET_RESCAN_TIMER',
  SET_RESCAN_COUNTER = 'PALM_READING_DAILY/SET_RESCAN_COUNTER',
}

export enum HANDS {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface Preview {
  uri: string;
  date: string; // if we have local copy, will be same as created_at field in report
}

export interface State {
  reports: Reports;
  previews: Previews; // local photo preview
  rescanTimers: RescanTimers;
  rescanCounters: RescanCounter;
}

export interface PalmMetadata {
  points?: Point[];
  lines?: LinesTransformed;
}

interface CompletedSteps {
  [STEPS.LEFT_HAND]: string | null;
  [STEPS.RIGHT_HAND]: string | null;
  [STEPS.FINGERS]: string | null;
}

export interface RescanTimers {
  [STEPS.LEFT_HAND]: string | null;
  [STEPS.RIGHT_HAND]: string | null;
  [STEPS.FINGERS]: string | null;
}

export interface RescanCounter {
  [STEPS.LEFT_HAND]: number;
  [STEPS.RIGHT_HAND]: number;
  [STEPS.FINGERS]: number;
}

export interface Previews {
  [HANDS.LEFT]?: Preview;
  [HANDS.RIGHT]?: Preview;
}

export interface Reports {
  [HANDS.LEFT]?: Report;
  [HANDS.RIGHT]?: Report;
}

export interface Report {
  id: number;
  type: string;
  created_at: string;
  path: string;
  result: ResultData;
}

export interface ResultData {
  core: CoreData;
  insights: InsightsData;
  heart: HeartData;
  life: LifeData;
  head: HeadData;
  fate: FateData;
}

export interface CoreData {
  lineScore: LineScore;
  lineSuggestion: LineSuggestion;
}

interface LineScore {
  heart: number;
  life: number;
  head: number;
  fate: number;
}

interface LineSuggestion {
  heart: string;
  life: string;
  head: string;
  fate: string;
}

interface InsightsData {
  topCareer?: string;
  emotionalType?: string;
  learningStyle?: string;
  lifeExpectancy?: number;
  marriageAge?: number[];
  wellBeingState?: {
    type: string;
    value: string;
  };
  decisionMaking?: string;
  potentialChallenges?: PotentialChallenge[];
}

export interface HeartData extends LineData {
  score: number;
  suggestion: string;
  emotionalType?: EmotionalType;
  idealPartner?: IdealPartner;
  approachToLove?: ApproachToLove;
  relationshipNeeds?: RelationshipNeed[];
  marriageAge?: number[];
  marriageAgeText?: string;
  parentalAge?: number[];
  parentalAgeText?: string;
  cognitiveIntelligence?: CognitiveIntelligence[];
}

export interface LifeData extends LineData {
  score: number;
  suggestion: string;
  lifeExpectancy?: number;
  lifeExpectancyText?: string;
  deceases?: Risks;
  addictions?: Risks;
  wellBeingState?: WellBeingState;
  goodHabits?: Habits;
  harmfulHabits?: Habits;
  stressTimes?: StressTimes;
}

export interface HeadData extends LineData {
  score: number;
  suggestion: string;
  decisionMaking?: DecisionMaking;
  values?: Value[];
  problemSolving?: ProblemSolving;
  cognitiveIntelligence?: CognitiveIntelligence[];
  talents: Talent[];
  personalityType?: PersonalityType[];
}

export interface FateData extends LineData {
  chanceOfSuccess: ChancesForSuccess[];
  score: number;
  suggestion: string;
  lifePath?: LifePath;
  careerPath?: CareerPath;
  sourceOfMotivation?: SourceOfMotivation;
  potentialChallenges?: PotentialChallenge[];
  topCareers: TopCareers;
}

interface TopCareers {
  professions: string[];
  slug: string;
  title: string;
  text: string;
}

interface EmotionalType {
  slug: string;
  title: string;
  text: string;
}

interface SourceOfMotivation {
  slug: string;
  title: string;
  text: string;
}

interface ApproachToLove {
  slug: string;
  title: string;
  text: string;
}

export interface CognitiveIntelligence {
  score: number;
  description: string;
  type: string;
}

export interface Risks {
  [key: string]: {
    value: number;
    text: string;
  };
}

interface Value {
  slug: string;
  title: string;
  text: string;
}

interface RelationshipNeed {
  slug: string;
  title: string;
  text: string;
}

interface DecisionMaking {
  slug: string;
  title: string;
  text: string;
}

interface ProblemSolving {
  slug: string;
  title: string;
  text: string;
}

interface Talent {
  score: number;
  slug: string;
  title: string;
  text: string;
}

interface LifePath {
  [key: string]: LifeEvent[];
}

interface CareerPath {
  [key: string]: CareerEvent[];
}

interface LifeEvent {
  type: string;
  value: number;
}

interface CareerEvent {
  type: string;
  value: number;
}

interface PotentialChallenge {
  slug: string;
  title: string;
  text: string;
}

interface LineData {
  overview: string;
  length: string;
  depth: string;
  suggestion: string;
  curvature: string;
}

interface IdealPartner {
  text: string;
  traits: string;
}

export interface ChartAspects {
  score: number;
  description: string;
  name: string;
}

interface WellBeingState {
  vitality: WellBeingAspect;
  libido: WellBeingAspect;
  resilience: WellBeingAspect;
}

interface WellBeingAspect {
  score: string;
  description: string;
}

interface Habits {
  [key: string]: HabitDetail;
}

interface HabitDetail {
  title: string;
  text: string;
}

interface StressTimes {
  times: { [key: string]: number };
  description: string;
}

export interface PersonalityType {
  text: string;
  slug: PersonalitySlugs;
  value: number;
  title: string;
}

export interface ChancesForSuccess {
  text: string;
  type: string;
  value: number;
}

interface ResetUserDataAction {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface SetReports {
  type: typeof TYPES.SET_REPORTS;
  payload: Reports;
}

interface SetReport {
  type: typeof TYPES.SET_REPORT;
  payload: { [key in HANDS]: Report };
}

interface SetReportPreview {
  type: typeof TYPES.SET_REPORT_PREVIEW;
  payload: { [key in HANDS]: Preview };
}

interface SetRescanTimerAction {
  type: typeof TYPES.SET_RESCAN_TIMER;
  payload: Partial<RescanTimers>;
}

interface SetRescanCounterAction {
  type: typeof TYPES.SET_RESCAN_COUNTER;
  payload: {
    stepId: keyof CompletedSteps;
    counter: number;
  };
}

export type ActionTypes = SetReports | SetReport | SetReportPreview | ResetUserDataAction | SetRescanTimerAction | SetRescanCounterAction;
