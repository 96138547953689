import { createSelector } from 'reselect';
import { Platform } from 'react-native';
import { Product } from '@wowmaking/react-native-billing';

import { RootState } from 'store';
import { Currency } from 'modules/payments/interfaces';
import { WEB_PAYMENT_SERVICES, AVAILABLE_ONECLICK_METHODS, WEB_SUBSCRIPTION_STATUS, CURRENCIES } from 'modules/payments/constants';
import { ONE_TIME_PRODUCTS } from 'modules/payments/constants/product';
import { WebOneTimePurchase, WebPurchase, PaymentMethods, WebProduct, WebSubscription } from 'api/purchases/interfaces';

const getWebAllSubscriptions = (state: RootState) => state.billing.webAllSubscriptions;
const getWebOneTimePurchases = (state: RootState) => state.billing.webOneTimePurchases;
const getWebPaymentMethod = (state: RootState) => state.billing.webPaymentMethod;
const getWebProducts = (state: RootState) => state.billing.webProducts;
const getPurchased = (state: RootState) => state.billing.purchased;
const getProducts = (state: RootState) => state.billing.products;
const getSubBenefitsConfig = (state: RootState) => state.remoteConfig.remoteConfigParams?.subBenefitsConfig || {};
const getOneClickEnableParam = (state: RootState) => state.remoteConfig.remoteConfigParams.isOneClickEnabled;

export const getCurrentWebPaymentServices = createSelector([getWebAllSubscriptions], (transactions: WebPurchase[]) => {
  return transactions.reduce((services: WEB_PAYMENT_SERVICES[], tr: any) => {
    if (tr.payment_service) {
      services.push(tr.payment_service);
    }
    return services;
  }, []);
});

export const getWebOneTimePurchaseProducts = createSelector(
  [getWebOneTimePurchases],
  (oneTimePurchases: WebOneTimePurchase[]): ONE_TIME_PRODUCTS[] => {
    return oneTimePurchases.map(purchase => purchase.product_code as unknown as ONE_TIME_PRODUCTS);
  },
);

export const getWebActiveSubscription = createSelector([getWebAllSubscriptions], (subscriptions: WebPurchase[]): WebPurchase | undefined => {
  return subscriptions.find(i => i?.active && !i?.canceled_at);
});

export const isSolidgatePaymentService = createSelector([getCurrentWebPaymentServices], paymentServices =>
  paymentServices.some((ps: WEB_PAYMENT_SERVICES) => [WEB_PAYMENT_SERVICES.SOLIDGATE].includes(ps)),
);

export const getPaymentEmail = createSelector(
  [getWebAllSubscriptions, getWebOneTimePurchases],
  (transactions: WebPurchase[], oneTimePurchases: WebOneTimePurchase[]) => {
    const email = transactions?.[transactions?.length - 1]?.email || oneTimePurchases?.[oneTimePurchases?.length - 1]?.email;
    return email || '';
  },
);

export const getPrevCurrency = createSelector(
  [getWebAllSubscriptions, getWebOneTimePurchases],
  (transactions: WebPurchase[], oneTimePurchases: WebOneTimePurchase[]): Currency => {
    const currency = transactions?.[0]?.currency || oneTimePurchases?.[0]?.currency;
    const isValidCurrency = (value: any): value is Currency => Object.values(CURRENCIES).includes(value);
    return isValidCurrency(currency) ? currency : CURRENCIES.USD;
  },
);

export const getPrevProject = createSelector([getWebOneTimePurchases], (oneTimePurchases: WebOneTimePurchase[]) => {
  return oneTimePurchases?.[0]?.project;
});

export const isSubBenefitsEnabled = createSelector([getPurchased, getSubBenefitsConfig], (purchased: boolean, { enabled }) => {
  return purchased && enabled;
});

export const checkOneClickAvailable = createSelector(
  [getWebPaymentMethod, getOneClickEnableParam],
  (paymentMethod: PaymentMethods | '', isEnabled: boolean) => {
    return isEnabled && paymentMethod && AVAILABLE_ONECLICK_METHODS.includes(paymentMethod);
  },
);

export const getWebSubscriptionToReactivate = createSelector(
  [getWebAllSubscriptions, getWebActiveSubscription],
  (webAllSubscriptions, activeSubscription): WebSubscription | null => {
    const inactiveSubscriptions = webAllSubscriptions.filter(s =>
      [WEB_SUBSCRIPTION_STATUS.CANCELED, WEB_SUBSCRIPTION_STATUS.EXPIRED].includes(s?.status),
    );

    const getLastUpdated = () => {
      const getUpdatedAt = ({ updated_at }) => {
        if (typeof updated_at !== 'object') {
          return new Date(updated_at);
        }

        return '';
      };

      return inactiveSubscriptions.reduce((latest, current) => {
        return latest && getUpdatedAt(latest) < getUpdatedAt(current) ? current : latest;
      }, inactiveSubscriptions[0]);
    };

    return !activeSubscription && inactiveSubscriptions.length ? getLastUpdated() : null;
  },
);

export const getWebProduct = createSelector([getWebProducts, (_state, id: string) => id], (webProducts, id): WebProduct | null => {
  return webProducts.find(p => p.id === id) || null;
});

export const getProduct = createSelector([getProducts, (_state, id: string) => id], (products, id): Product | null => {
  return products.find(p => p.productId === id) || null;
});

export const getPlatformProducts = createSelector([getWebProducts, getProducts], (webProducts, products): WebProduct[] | Product[] => {
  if (Platform.OS === 'web') {
    return webProducts;
  } else {
    return products;
  }
});
