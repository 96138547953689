import { paddingHorizontal, paddingVertical, fs } from '@wowmaking/ui/src/utils';
import { TextStyle } from 'react-native';

import styles, {
  ValidStylePath,
  ValidMixinsPath,
  ValidColorsPath,
  ValidSizesPath,
  ValidSpacingsPath,
  ValidTypographyPath,
  ValidGradientsPath,
  ValidLinearGradientsPath,
  ValidAngularGradientsPath,
  ValidRadialGradientsPath,
  ValidPalettePath,
} from '../default-styles';

export const ds = (path: ValidStylePath): any => {
  const keys = path.split('/');
  let result: any = styles;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  if (path.startsWith('sizes/spacings/horizontal/')) {
    return paddingHorizontal(result);
  }

  if (path.startsWith('sizes/spacings/vertical/')) {
    return paddingVertical(result);
  }

  if (path.startsWith('typography/font_size/')) {
    return fs(result);
  }

  return result;
};

export const dsColor = (path: ValidColorsPath): string => {
  const keys = path.split('/');
  let result: any = styles.colors;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Color path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  return result;
};

export const dsPalette = (path: ValidPalettePath): string => {
  const keys = path.split('/');
  let result: any = styles.palette;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Palette path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  return result;
};

export const dsGradient = (path: ValidGradientsPath): any => {
  const keys = path.split('/');
  let result: any = styles.gradients;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Gradient path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  return result;
};

export const dsLinearGradient = (path: ValidLinearGradientsPath): any => {
  const keys = path.split('/');
  let result: any = styles.gradients.linear;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Linear Gradient path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  return result;
};

export const dsAngularGradient = (path: ValidAngularGradientsPath): any => {
  const keys = path.split('/');
  let result: any = styles.gradients.angular;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Angular Gradient path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  return result;
};

export const dsRadialGradient = (path: ValidRadialGradientsPath): any => {
  const keys = path.split('/');
  let result: any = styles.gradients.radial;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Radial Gradient path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  return result;
};

export const dsTypography = (path: ValidTypographyPath): any => {
  const keys = path.split('/');
  let result: any = { ...styles.typography };

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Typography path "${path}" is invalid.`);
      return result;
    }

    result = result[key];
  }

  if (keys.includes('font_size')) {
    return fs(result);
  }

  return result;
};

export const dsSpacing = (path: ValidSpacingsPath): any => {
  const keys = path.split('/');
  let result: any = styles.sizes;
  let k: string = '';

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Spacing path "${path}" is invalid.`);
      return result;
    }

    k = key;
    result = result[key];
  }

  if (k.startsWith('h_')) {
    return paddingHorizontal(result);
  }

  if (k.startsWith('v_')) {
    return paddingVertical(result);
  }

  return result;
};

export const dsSize = (path: ValidSizesPath): any => {
  const keys = path.split('/');
  let result: any = styles.sizes;
  let k: string = '';

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Size path "${path}" is invalid.`);
      return result;
    }
    k = key;
    result = result[key];
  }

  if (k.startsWith('h_')) {
    return paddingHorizontal(result);
  }

  if (k.startsWith('v_')) {
    return paddingVertical(result);
  }

  return result;
};

export const dsMixin = (path: ValidMixinsPath): TextStyle => {
  const keys = path.split('/');
  let result: any = styles.mixins;

  for (const key of keys) {
    if (result[key] === undefined) {
      console.error(`[DS]: Mixin path "${path}" is invalid.`);
      return result;
    }
    result = result[key];
  }

  const newResult = { ...result };

  if ('fontSize' in newResult) {
    newResult.fontSize = fs(newResult.fontSize);
  }

  if ('letterSpacing' in newResult) {
    newResult.letterSpacing = fs(newResult.letterSpacing);
  }

  if ('lineHeight' in newResult) {
    newResult.lineHeight = fs(newResult.lineHeight);
  }

  return newResult;
};
